import { createStore } from 'vuex'
import avatar from '@/store/modules/avatar'
import user from '@/store/modules/user'

export default createStore({
  modules: {
    avatar,
    user
  }
})
