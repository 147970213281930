import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

createApp(App)
  .use(store)
  .use(router)
  .use(FloatingVue)
  .use(Vue3Lottie)
  .mount('#app')
