export const avatarNames = {
  setBase: "avatar/setBase",
  setBackground: "avatar/setBackground",
  setShirt: "avatar/setShirt",
  setSkinTone: "avatar/setSkinTone",
  setFace: "avatar/setFace",
  setHairType: "avatar/setHairType",
  setHairColor: "avatar/setHairColor",
  setFacialHairType: "avatar/setFacialHairType",
  setFacialHairColor: "avatar/setFacialHairColor",
  setAccessories: "avatar/setAccessories",
  setAvatar: "avatar/setAvatar"
};

export default {
  state: {
    base: 0,
    background_color: "#7E4AA7",
    shirt_color: "#FFFFFF",
    skin_tone: "#F7D8D0",
    face_type: 1,
    hair_color:"#ECE6E6",
    hair_type:0,
    facial_hair_type:0,
    facial_hair_color:"#ECE6E6",
  },
  getters: {},
  mutations: {
    [avatarNames.setBase](state, base) {
      state.base = base;
    },
    [avatarNames.setBackground](state, background) {
      state.background_color = background;
    },
    [avatarNames.setShirt](state, shirt) {
      state.shirt_color = shirt;
    },
    [avatarNames.setAvatar](state, avatar) {
      if(avatar.base) state.base= avatar.base
      if(avatar.background_color) state.background_color = avatar.background_color
      if(avatar.shirt_color) state.shirt_color=avatar.shirt_color
      if(avatar.skin_tone) state.skin_tone= avatar.skin_tone
      if(avatar.face_type) state.face_type = avatar.face_type
      if(avatar.hair_color) state.hair_color=avatar.hair_color
      if(avatar.hair_type) state.hair_type=avatar.hair_type
      if(avatar.facial_hair_type) state.facial_hair_type=avatar.facial_hair_type
      if(avatar.facial_hair_color) state.facial_hair_color=avatar.facial_hair_color
    },
    [avatarNames.setSkinTone](state, skinTone) {
      state.skin_tone = skinTone;
    },
    [avatarNames.setFace](state, face) {
      state.face_type = face;
    },
    [avatarNames.setHairType](state, hair) {
      state.hair_type = hair;
    },
    [avatarNames.setFacialHairType](state, facialHair) {
      state.facial_hair_type = facialHair;
    },
    [avatarNames.setHairColor](state, hair) {
      state.hair_color = hair;
    },
    [avatarNames.setFacialHairColor](state, facialHair) {
      state.facial_hair_color = facialHair;
    },
   
  },
};
