import { authClient } from '@/api/clients'

const endpoints = {
  register: '/register',
  login: '/login',
  validate: '/validate',
  logout: '/logout'
}

export default class AuthAPI {
  static async register (user) {
    const response = await authClient.post(endpoints.register, user)
    return response.data
  }

  static async login (user) {
    const response = await authClient.get()
    return response.data
  }

  // static async validate () {
  //   const response = await authClient.get(endpoints.validate)
  //   return response.data
  // }

  static async logout () {
    const response = await authClient.post(endpoints.logout)
    return response.data
  }
}
